import styled, { css } from "styled-components";
import InputMask from "react-input-mask";

export const Container = styled.div`
  text-align: left;
  padding: 2px;
  width: 100%;
  div {
    margin-bottom: 5px;
    font-family: "Poppins", sans-serif;
    color: var(--purple);
    font-size: 14px;
    span {
      color: var(--red);
    }
  }
`;

export const InputContainer = styled.div`
  /* background: var(--white); */
  border-radius: 4px;
  border: 2px solid var(--black);
  color: var(--gray);
  padding: 10px;
  width: 100%;
  display: flex;
  transition: 0.4s;
  height: ${(props) => (props.isContactUs ? "150px" : "40px")};

  ${(props) =>
    props.isErrored &&
    css`
      border-color: var(--red);
      svg {
        color: var(--red);
      }
    `}

  input {
    background: transparent;
    align-items: center;
    font-family: "Poppins", sans-serif;
    flex: 1;
    border: 0;
    color: var(--black);
    &::placeholder {
      color: var(--gray);
    }
  }
  svg {
    margin-right: 16px;
  }
`;

export const MaskedInput = styled(InputMask)`
  /* height: 35px;
  font-size: 16px;
  padding: 0px 8px 0px 8px; */
`;

export const Describe = styled.span`
  display: flex;
  font-size: 12px !important;
  margin-top: 0 !important;
  width: 100%;
`