import React, { useEffect, useState } from "react";
import LogoPic from "../../assets/Logotipo_boraver.png";
import { Content } from "./styles";
import { useHistory, useLocation } from "react-router-dom";
import { AiOutlineMessage } from "react-icons/ai";
import { BsHouseDoor } from "react-icons/bs";
import { FaRegFolderOpen } from "react-icons/fa";
import { FiUsers, FiLogOut } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { RiFileEditLine } from "react-icons/ri";
import Target from "../../assets/target-black.svg";
import { useAuth } from "../../providers/auth";
import { useClient } from "../../providers/user";

const Sidebar = ({ visibleSidebar, toggleSidebar }) => {
  const { setAuth } = useAuth();
  const { setUserId } = useClient();
  const history = useHistory();
  const location = useLocation();
  const [widthScreen, setWidthScreen] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidthScreen(window.innerWidth); // Atualizando o estado com a largura atualizada

      if (window.innerWidth < 844 && visibleSidebar) {
        toggleSidebar()
      } else if (window.innerWidth > 843 && !visibleSidebar) {
        toggleSidebar()
      }
    };

    window.addEventListener('resize', handleResize);

    // Limpando o ouvinte ao desmontar o componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [visibleSidebar]);

  const itensMenuSidebar = [
    {
      id: 1,
      label: "Início",
      icon: <BsHouseDoor size={18} style={{marginRight: 8}} />,
      url: "/dashboard",
    },
    {
      id: 2,
      label: "Vitrine de Creators",
      icon: <FiUsers size={18} style={{marginRight: 8}} />,
      url: "/vitrine-creators",
    },
    {
      id: 3,
      label: "Minhas Campanhas",
      icon: <FaRegFolderOpen size={18} style={{marginRight: 8}} />,
      url: "/campaign",
    },
    {
      id: 4,
      label: "Criar Campanha",
      icon: <img src={Target} width={18} style={{marginRight: 8}} />,
      url: "/new-campaign",
    },
    {
      id: 5,
      label: "Central de Propostas",
      icon: <RiFileEditLine size={18} style={{marginRight: 8}} />,
      url: "/solicitations",
    },
    // {
    //   id: 6,
    //   label: "Mensagens",
    //   icon: <AiOutlineMessage size={18} style={{marginRight: 8}} />,
    //   url: "/messages",
    // },
  ];

  const handleNavigation = (path) => {
    if (widthScreen < 844) {
      toggleSidebar();
    }
    return history.push(path);
  };

  const handleLogout = () => {
    setAuth()
    setUserId()
    localStorage.removeItem('@boraver')
    return history.push('/')
  }

  return (
    <Content visibleSidebar={visibleSidebar}>
      <div className="headerModal">
        <div className="logoDiv">
          <img src={LogoPic} alt="logo-boraver" />
        </div>
        <div><IoMdClose fill="#000" onClick={() => toggleSidebar()} /></div>
      </div>
      <div className="contentModal">
        <ul>
          {itensMenuSidebar.map((item) => (
            <li
              key={item.id}
              className={location.pathname === item.url ? "active" : ""}
              onClick={() => handleNavigation(`${item.url}`)}
            >
              {item.icon} {item.label}
            </li>
          ))}
        </ul>
      </div>
      <div className="logout" onClick={() => handleLogout()}>
          <FiLogOut size={18} color="var(--black)" /> Sair
      </div>
    </Content>
  );
};

export default Sidebar;
