import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 50px 0 0;
`;

export const Title = styled.h2`
  color: var(--black);
  font-family: Inconsolata;
  font-size: 40px;
  font-weight: 800;
  line-height: 42px;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  
  @media (max-width: 700px) {
    font-size: 34px;
    line-height: 36px;
  }
  
  @media (max-width: 425px) {
    font-size: 28px;
    line-height: 30px;
  }
`;

export const Subtitle = styled.p`
  color: var(--black);
  font-family: Insoncolata;
  font-size: 24px;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 8px;
  text-align: center;
  width: 90%;

  @media (max-width: 700px) {
    font-size: 20px;
    line-height: 22px;
  }

  @media (max-width: 425px) {
    font-size: 16px;
    line-height: 18px;
  }
`;

export const DivCenter = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
`;

export const DivRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    padding-inline: 40px;
`;

export const DivColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-inline: 20px;
  width: 23%;
`;