import React from "react";
import { Card, Header, Title, Body, Footer, Creator, Segmento } from "./styles";

const CardCreator = ({ image, creator, segmento }) => {
  const handleNavigation = () => {
    const url = `/influencerdetails/${creator.id}`;
    
    // Armazenar o estado no sessionStorage
    sessionStorage.setItem('influencer', JSON.stringify(creator));
    
    const fullUrl = `${window.location.origin}/#${url}`;
    window.open(fullUrl, '_blank');
  }  
  return (
    <Card onClick={() => handleNavigation()}>
      <Header>
        <Title>Creator</Title>
      </Header>
      <Body>
        <img src={image} />
      </Body>
      <Footer>
        <Creator>{creator.nome}</Creator>
        <Segmento>
          {segmento.map((item) => `${item.nome}`).join(", ")}
        </Segmento>
      </Footer>
    </Card>
  );
};

export default CardCreator;
