import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 2;
  min-width: 100vw;
  height: 100vh;
  position: fixed;
  inset: 0;
  left: ${(props) => (props.isMenu ? 0 : "")};
  background-color: rgba(0, 0, 0, 0.5);
  @media (max-width: 500px) {
    min-width: 100%;
    height: 115vh;
    top: 0;
    position: absolute;
  }
`;

export const Content = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  position: fixed;
  top: 30px;
  bottom: 30px;
  left: ${(props) => (props.isVisible ? "0" : "")};
  border-radius: 8px;
`;

export const HeaderModal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  height: 70px;

  h2 {
    text-align: center;
    color: var(--black);
    font-size: 18px;
    margin-left: 20px;
  }

  button {
    border: none;
    background-color: var(--white);
    font-size: 20px;
    font-weight: 600;
    margin-right: 20px;
    color: var(--mediumgray);
    cursor: pointer;
    :hover {
      color: var(--black);
    }
  }
`;

export const BodyModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
`;

export const BlackCard = styled.div`
  background-color: rgb(50, 42, 50);
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  padding: 20px;
  transition: all 0.3s;

  &:hover {
    transform: scale(1.02);
  }

  .card-icon {
    background-color: var(--white);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    padding: 10px;
    height: 120px;
    width: 120px;

    svg {
      height: 100px;
      width: 100px;
    }
  }

  h3,
  p {
    color: var(--white);
    margin-top: 10px;
    text-align: center;
  }

  h3 {
    font-size: 20px;
  }

  p {
    font-size: 13px;
    width: min(100%, 18ch);
  }
`;

export const ShareLinks = styled.div`
    display: flex;
    cursor: pointer;
    gap: 30px;
    margin-top: 30px;
`;

export const CardLink = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const DivIcon = styled.div`
    background-color: ${(props) => props.color ? props.color : ""};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    padding: 10px;
    margin-bottom: 10px;
    width: 50px;
`;