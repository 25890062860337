import React, { useState } from "react";
import { Background, Card, Container, DivCards, Image, Main, Subtitle, Title } from "./styles";
import Logo from "../../assets/Logotipo_boraver_SemFundo-09.png";
import { FaApple } from "react-icons/fa";
import { GrAndroid } from "react-icons/gr";
import { FaIndustry } from "react-icons/fa";
import { GoKebabVertical } from "react-icons/go";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ModalShare from "../../components/ModalShare/ModalShare";

const LinksCreator = () => {
    const history = useHistory();
    const [visibleModal, setVisibleModal] = useState(false);
    const [itemSelected, setItemSelected] = useState(null);
    const content = [
        {
            "id": 1,
            "link": "https://apps.apple.com/br/app/boraver-ugc/id6444215783",
            "icon": <FaApple size={28} />,
            "description": "BAIXAR APLICATIVO (Iphone)"
        },
        {
            "id": 2,
            "link": "https://play.google.com/store/apps/details?id=boraver.com&pli=1",
            "icon": <GrAndroid size={28} />,
            "description": "BAIXAR APLICATIVO (Android)"
        },
        {
            "id": 3,
            "link": "/signup",
            "icon": <FaIndustry size={28} />,
            "description": "CADASTRE SUA EMPRESA AQUI"
        },
    ]

    const onPress = (item) => {
        setVisibleModal(true);
        setItemSelected(item);
    }

    const handleClick = (url) => {
        if (window.fbq) {
            if (url !== "/signup") {
                window.fbq('track', 'purchase', {
                    content_name: url,
                    event_description: 'Usuário clicou em um link',
                });
            }
        }

        if (url === "/signup") {
            history.push(url);
        } else {
            window.open(url, "_blank", "noopener noreferrer");
        }
    }

    return (
        <Container>
            <Background />
            <Main>
                <Image src={Logo} alt="" />
                <Title>@BoraverApp</Title>
                <Subtitle>
                    Conte com nossa ajuda para encontrar oportunidades e monetizar seu conteúdo!
                </Subtitle>
                <DivCards>
                    {content.map((item) => (
                        <Card key={item.id} onClick={() => handleClick(item.link)}>
                            {/* <a href={item.link} target="_blank" rel="noopener noreferrer"> */}
                                {item.icon}
                                <p>{item.description}</p>
                            {/* </a> */}
                            {/* <GoKebabVertical size={28} onClick={() => onPress(item)} /> */}
                        </Card>
                    ))}
                </DivCards>
            </Main>
            {visibleModal ? <ModalShare setVisibleModal={setVisibleModal} item={itemSelected} /> : null}
        </Container>
    )
}

export default LinksCreator;