import React from "react";
import { Container, DivColumn, DivRow, LargeImage, SmallImage, Title } from "./styles";
import Influencer1 from "../../assets/influencer-1.jpeg";
import Influencer2 from "../../assets/influencer-2.jpeg";
import Influencer3 from "../../assets/influencer-3.jpeg";
import Influencer4 from "../../assets/influencer-4.jpeg";
import Influencer5 from "../../assets/influencer-5.jpeg";
import Influencer6 from "../../assets/influencer-6.jpeg";
import Influencer7 from "../../assets/influencer-7.jpeg";
import Influencer8 from "../../assets/influencer-8.jpeg";

const SectionInspired = () => {
    return (
        <Container>
            <Title>
                Inspire-se com Creators
            </Title>
            <DivRow>
                <DivColumn>
                    <LargeImage>
                        <img src={Influencer1} />
                    </LargeImage>
                    <SmallImage>
                        <img src={Influencer8} />
                    </SmallImage>
                </DivColumn>
                <DivColumn>
                    <SmallImage>
                        <img src={Influencer2} />
                    </SmallImage>
                    <LargeImage>
                        <img src={Influencer7} />
                    </LargeImage>
                </DivColumn>
                <DivColumn>
                    <LargeImage>
                        <img src={Influencer3} />
                    </LargeImage>
                    <SmallImage>
                        <img src={Influencer6} />
                    </SmallImage>
                </DivColumn>
                <DivColumn>
                    <SmallImage>
                        <img src={Influencer4} />
                    </SmallImage>
                    <LargeImage>
                        <img src={Influencer5} />
                    </LargeImage>
                </DivColumn>
            </DivRow>
        </Container>
    )
}

export default SectionInspired
