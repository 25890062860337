import { Container } from "./styles"

const Button = ({children, setBackgroundColor, setColor, setShadow, disabled, large, ...rest}) => {
  return(
    <Container 
      setBackgroundColor={setBackgroundColor}
      setColor={setColor}
      setShadow={setShadow}
      large={large}
      disabled={disabled}
      type="button"
      {...rest}
    >
      {children}
    </Container>
  )
}
export default Button