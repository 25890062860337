import styled from "styled-components";
import BannerPic from "../../assets/BannerPic.png";

export const Container = styled.div`
  background: rgba(0, 0, 0, 0.5) url(${BannerPic});
  background-size: cover;
  background-blend-mode: darken;
  max-width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  
  @media (max-width: 425px) {
    height: 90%;
  }

  h1 {
    width: 60%;
    padding: 8rem 0 6rem 0;
    text-align: center;
    font-weight: 600;
    color: var(--white);
    /* background-color: pink; */
    @media (max-width: 768px) {
      width: 90%;
    }
    @media (max-width: 425px) {
      font-size: 24px;
    }
  }

  button {
    margin-bottom: 5rem;
    color: var(--white);
    padding: 1rem 6rem;
    border: 2px dashed var(--white);
    border-radius: 16px;
    background-color: transparent;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
  }
`;
