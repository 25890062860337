import { useEffect } from "react";
import { useForm } from "react-hook-form"
import { useParams } from 'react-router-dom'
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import Header from "../../components/Header/Header";
import Footer from '../../components/Footer/Footer'
import Input from '../../components/Input/Input'
import LoginPic from '../../assets/LoginPic.png'
import { Content, Form, LeftSide, RightSide } from "./styles";
import Button from "../../components/Button/Button";
import { useHistory } from "react-router-dom";
import api from "../../services";
import { toast } from "react-toastify";
import { useAuth } from "../../providers/auth";

const ChangePass = () => {

  const { uidb, token } = useParams()

  const { auth } = useAuth()

  const history = useHistory()

  const schema = yup.object().shape({
    new_password1: yup.string().min(4, "A senha deve conter no mínimo 4 caracteres!").required("Campo obrigatório!"),
    new_password2: yup.string().oneOf([yup.ref("new_password1")], "senhas diferentes!").required("Campo obrigatório!")
  })

  const {register, handleSubmit, formState: {errors}} = useForm({resolver: yupResolver(schema)})

  const handleChangePass = (data) => {
    const regex = /\W|_/;

    let reqBody = data
    reqBody.uid = uidb
    reqBody.token = token

    if(regex.test(reqBody.new_password1) && reqBody.new_password1.length >= 8){
      api
          .post(`/api/v3/password/reset/confirm`, reqBody)
          .then((res) => {
              toast.success("Senha alterada com sucecsso!")
              return history.push('/login')
          })
          .catch((res)=> toast.error("Alguma coisa deu errado, tente novamente com uma senha forte!"))
    } else{
      toast.error("A senha precisa ter pelo menos 8 digitos e um caractere especial, além de ter letras")
    }
  }

  const handleNavigation = (path) => {
    return history.push(path)
  }

  useEffect(() => {
    if(auth){
      return history.push("/dashboard")
    }
  }, [])

  return(
    <div>
      {/* <Header /> */}
      <Content>
        <LeftSide>
          <Form onSubmit={handleSubmit(handleChangePass)}>
            <h2>Confirme para resetar</h2>
            <Input 
              register={register}
              name="new_password1"
              label="Nova senha"
              placeholder="Digite a nova senha"
              type="password"
              error={errors.new_password1?.message}
            />
            <Input 
              register={register}
              name="new_password2"
              label="Confirme a senha"
              placeholder="Confirme a sua senha"
              type="password"
              error={errors.new_password2?.message}
            />
            <span>Ainda não possui cadastro? Cadastre-se <a onClick={() => handleNavigation("/signup")}>aqui</a></span>
            <span>Lembrou sua senha? Clique <a onClick={() => handleNavigation("/login")}>aqui</a></span>
            <div className="btn-place">
              <Button 
                setBackgroundColor="var(--purple)"
                setColor="var(--white)"
                large
                type="submit"
              >
                Resetar
              </Button>
            </div>
          </Form>
        </LeftSide>
        <RightSide>
          <img src={LoginPic} alt="login-boraver" />
        </RightSide>
      </Content>
      {/* <Footer /> */}
    </div>
  )
}
export default ChangePass