import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 6rem 0;
  margin-bottom: 2rem;
  max-width: 100%;

  img {
    max-width: 400px;
  }
  @media (max-width: 1023px) and (min-width: 769px) {
    padding: 4rem 0;
  }
  @media (min-width: 1024px) {
    justify-content: space-evenly;
  }
`;

export const LeftSide = styled.div`
  width: 50vw;
  display: flex;
  justify-content: center;

  @media (max-width: 1024px) {
    width: 60vw;
  }
  @media (max-width: 768px) {
    width: 100vw;
  }
`;

export const RightSide = styled.div`
  max-width: 50%;
  display: flex;
  align-items: center;
  img {
    width: 90%;
  }
  @media (max-width: 1024px) {
    max-width: 40%;
  }
  img {
    width: 100%;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 80%;
  max-width: 500px;
  border-radius: 10px;
  padding: 2rem 2rem;
  box-shadow: 5px 5px 12px 2px #646464;

  h2 {
    text-align: center;
    color: var(--purple);
    padding-bottom: 2rem;
    text-shadow: 2px 2px 4px rgba(100, 100, 100, 0.5);
  }
  .btn-place {
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }
  span {
    text-align: center;
    color: #646464;
    margin-top: 20px;
    font-size: 14px;
    a {
      font-weight: 600;
      cursor: pointer;
      color: var(--purple);
      :hover {
        color: var(--brightpurple);
      }
    }
  }
`;
