import Picture from '../../assets/user.webp'
import { Container, Content, SectionModal } from './style'
import { FiDownload } from 'react-icons/fi'
import { useState } from 'react';
import ReactModal from 'react-modal';

const Message = ({ imageInflu, message, from }) => {

  const [shouldOpenModal, setShouldOpenModal] = useState(false);
  const [srcFile, setSrcFile] = useState({file: "", alt: ""});
  
  const handleSaveFile = (file) => {
    let fileUrl = file.url;
    const fileName = file.text;
    
    if (!fileUrl.includes(["https://s3.us-west-004.backblazeb2.com"])) {
      
      fileUrl =  `https://s3.us-west-004.backblazeb2.com${fileUrl}`
    }

    // Criando o link para o download do arquivo
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;

    // Acionando o evento click do link para iniciar o download do arquivo
    link.click();

    // Revogando a URL do objeto Blob
    window.URL.revokeObjectURL(fileUrl);

  }

  const handleSrcFile = (file) => {
    setSrcFile({
      ...srcFile,
      file: file.url,
      alt: file.text
    });
    setShouldOpenModal(true)
  }

  return(
    <Container>
      <Content from={from}>
        <img className='profile' src={imageInflu ? imageInflu : Picture} alt="" />
        <div className='contentMessage'>
          { message.type === "media" ?
            <button onClick={() => handleSrcFile(message)}>
              <img src={message.url} alt="Foto"/> 
            </button>
           : message.type === "document" ?
            <>
              {message.text} {" "} <button onClick={() => handleSaveFile(message)}><FiDownload /></button> 
            </>
           : message.text}
        </div>
      </Content>
      <ReactModal
        isOpen={shouldOpenModal}
        onRequestClose={() => setShouldOpenModal(false)}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            display: 'inline-grid',
            width: '60%',
            maxHeight: '90%',
            padding: 10
          }
        }}>
          <SectionModal>
            <img src={srcFile.file} alt={srcFile.alt} />
          </SectionModal>
        </ReactModal>
    </Container>
  )
}
export default Message