import { createContext, useContext, useState } from "react";

const AuthContext = createContext();

export const AuthProvider = ({children} ) => {
  
  const userLoged = localStorage.getItem("userBoraVer") || "";
  const parsed = userLoged ? JSON.parse(userLoged) : null
  const [auth, setAuth] = useState(parsed ? parsed.token : userLoged);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};
export const useAuth = () => useContext(AuthContext);