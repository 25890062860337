import socketIO from "socket.io-client";
import { chatUrl } from "./chat";

const server = chatUrl;

export const socket = socketIO(server);

export function enterRoom(room) {
  socket.emit("enter-room", room);
}

export function leaveRoom(room) {
  socket.emit("leave-room", room);
}

export function addMessage(message) {
  socket.emit("add-message", message);
}

export function createChatRoom(room) {
  socket.emit("room", room);
}
