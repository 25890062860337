import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-inline: auto;
  padding: 50px 10px;
  width: 90%;
`;

export const Title = styled.h2`
  color: var(--black);
  font-family: Inconsolata;
  font-size: 40px;
  font-weight: 800;
  line-height: 42px;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  
  @media (max-width: 700px) {
    font-size: 34px;
    line-height: 36px;
  }
  
  @media (max-width: 425px) {
    font-size: 28px;
    line-height: 30px;
  }
`;

export const Subtitle = styled.p`
  color: var(--black);
  font-family: Insoncolata;
  font-size: 24px;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 8px;
  text-align: center;

  @media (max-width: 700px) {
    font-size: 20px;
    line-height: 22px;
  }

  @media (max-width: 425px) {
    font-size: 16px;
    line-height: 18px;
  }
`;

export const DivRow = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 30px;

    @media (max-width: 990px) {
      flex-wrap: wrap;
    }
`;