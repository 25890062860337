import React from "react";
import { BodyModal, BlackCard, Container, Content, HeaderModal, ShareLinks, CardLink, DivIcon } from "./styles";
import { IoMdClose, IoLogoWhatsapp } from "react-icons/io";
import { RiLinkM } from "react-icons/ri";
import api from "../../services/index";

const ModalShare = ({ setVisibleModal, item }) => {
    const itensShared = [
        {
            "id": 1,
            "icon": <RiLinkM size={24} fill="var(--black)" />,
            "description": "Copiar Link",
            "color": "#e0e2d9",
            "link": `${api}/links-creator`
        },
        {
            "id": 2,
            "icon": <IoLogoWhatsapp size={24} fill="var(--white)" />,
            "description": "WhatsApp",
            "color": "#00e676",
            "link": "https://api.whatsapp.com/send/?text=BAIXAR+APLICATIVO+%28Iphone%29+-+https%3A%2F%2Ftr.ee%2FvmrvVbPsRt&type=custom_url&app_absent=0"
        },
    ]
    const onPress = (url) => {
        window.open(url, "_blank");
    }
    return (
        <Container>
            <Content>
                <HeaderModal>
                    <h2>Compartilhar</h2>
                    <button onClick={() => setVisibleModal(false)}>
                        <IoMdClose />
                    </button>
                </HeaderModal>
                <BodyModal>
                    <BlackCard onClick={() => onPress(item.link)}>
                        <div className="card-icon">
                            {item.icon}
                        </div>
                        <h3>{item.description}</h3>
                        <p>{item.link.slice(5, 20)}...</p>
                    </BlackCard>
                    <ShareLinks>
                        {itensShared.map((itemShared) => (
                            <CardLink key={itemShared.id} onClick={() => onPress(itemShared.link)}>
                                <DivIcon color={itemShared.color}>
                                    {itemShared.icon}
                                </DivIcon>
                                <p>{itemShared.description}</p>
                            </CardLink>
                        ))}
                    </ShareLinks>
                </BodyModal>
            </Content>
        </Container>
    )
}

export default ModalShare;