import React from 'react';
import Sidebar from '../Sidebar/Sidebar';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import PageWrapper from '../PageWrapper/PageWrapper';

import { Layout } from './styles';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const LayoutWrapper = ({ children, toggleSidebar, isAuth, visibleSidebar, showDivZap }) => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <Layout isAuth={isAuth} visibleSidebar={visibleSidebar}>
      {isAuth && <Sidebar visibleSidebar={visibleSidebar} toggleSidebar={toggleSidebar} />}
      {currentPath !== "/links-creator" ? <Header isAuth={isAuth} visibleSidebar={visibleSidebar} toggleSidebar={toggleSidebar} /> : null}
      <main visibleSidebar={visibleSidebar}>
        <PageWrapper showDivZap={showDivZap}>{children}</PageWrapper>
      </main>
      {currentPath !== "/links-creator" ? <Footer /> : null}
    </Layout>
  )
};

export default LayoutWrapper;
