import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-inline: auto;
  padding: 50px 10px;
  width: 90%;
`;

export const Title = styled.h2`
  color: var(--black);
  font-family: Inconsolata;
  font-size: 40px;
  font-weight: 800;
  line-height: 42px;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  
  @media (max-width: 700px) {
    font-size: 34px;
    line-height: 36px;
  }
  
  @media (max-width: 425px) {
    font-size: 28px;
    line-height: 30px;
  }
`;

export const Subtitle = styled.p`
  color: var(--black);
  font-family: Insoncolata;
  font-size: 24px;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 8px;
  text-align: center;

  @media (max-width: 700px) {
    font-size: 20px;
    line-height: 22px;
  }

  @media (max-width: 425px) {
    font-size: 16px;
    line-height: 18px;
  }
`;

export const DivVideo = styled.div`
    border: 3px solid var(--black-50);
    box-shadow: -5px 5px 0 0 #1e1e1e;
    height: 675px;
    overflow: hidden;
    margin-inline: auto;
    margin-top: 30px;
    margin-bottom: 40px;
    width: 84%;

    video, iframe {
        height: 100%;
        width: 100%;
    }

    @media (max-width: 1100px) {
      height: 520px;
    }

    @media (max-width: 900px) {
      height: 400px;
    }

    @media (max-width: 700px) {
      height: 300px;
    }

    @media (max-width: 550px) {
      height: 230px;
    }

    @media (max-width: 425px) {
      height: 200px;
    }

    @media (max-width: 375px) {
      height: 170px;
    }
`;
