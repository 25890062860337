import Button from '../Button/Button'
import { Container, Content } from "./styles"
import LogoPic from '../../assets/LogoPic.png'

const Modal = ({setShouldOpenModal, setShowNewService, modalIsOpen, title, children, isMenu}) => {
  return(
    <Container>
      <Content isMenu={!!isMenu && isMenu}>
        <div className="headerModal">
          {
            isMenu 
            ? (
              <div className='logoDiv'>
                <img src={LogoPic} alt="logo-boraver" /> 
              </div>
            )
            :<h2>{title}</h2>
          }
          <button 
            type='button' 
            onClick={() => {
              setShouldOpenModal(false);
              setShowNewService(false);
            }}
          > 
            X
          </button>
        </div>
        <div className='contentModal'>
          {children}
        </div>
      </Content>
    </Container>
  )
}
export default Modal