import styled from "styled-components";

export const Container = styled.div`
  background-color: var(--gray);
  padding: 3rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    color: var(--purple);
    font-size: 32px;
    text-align: center;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 3rem 0 3rem 0;
  width: 80%;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 395px) {
    width: 94%;
  }
`;

export const Box = styled.div`
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 5px 5px 10px 2px #646464;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 30%;
  padding: 2rem;
  text-align: center;

  p {
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    max-width: 80%;
    margin: 1rem 0;
  }

  @media (max-width: 457px) {
    max-width: 90%;
  }
`;
