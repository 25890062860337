import axios from "axios";

const bucket = axios.create({
  baseURL: 'https://bucket.devari.com.br/api/v1'
})

// nome da pasta = cliente
export async function uploadFile(folder, payload) {
  try {
    const response = await bucket.post(
      `aws/s3-boraverapp/${folder}/devariupload/`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data.result[0].Location;
  } catch (err) {
    throw Error("Ocorreu um erro ao tentar fazer o upload do arquivo");
  }
}