import React from "react";
import { Container, Title, Subtitle, Description, DivRow, DivColumn } from "./styles";
import { BsChatDots, BsCart3 } from "react-icons/bs";
import { FaCheck } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";

const SectionHowWork = () => {
    return (
        <Container>
            <Title>Como Funciona?</Title>
            <DivRow>
                <DivColumn>
                    <FiSearch size={28} color="var(--purple)" />
                    <Subtitle>Busque um Creator</Subtitle>
                    <Description>Navegue pela vitrine de criadores de conteúdo e explore seus perfis.</Description>
                </DivColumn>
                <DivColumn>
                    <BsChatDots size={28} color="var(--purple)" />
                    <Subtitle>Converse com o Creator</Subtitle>
                    <Description>Aperte em 'Enviar mensagem' em um dos pacotes que lhe interessar para iniciar rapidamente uma conversa com o criador de conteúdo.</Description>
                </DivColumn>
                <DivColumn>
                    <BsCart3 size={28} color="var(--purple)" />
                    <Subtitle>Solicite Seu Pacote</Subtitle>
                    <Description>Após visitar o portfólio do criador de conteúdo e ver seus pacotes, você pode negociar de forma rápida e prática diretamente com ele.</Description>
                </DivColumn>
                <DivColumn>
                    <FaCheck size={28} color="var(--purple)" />
                    <Subtitle>Receba Seu Conteúdo</Subtitle>
                    <Description>Você receberá o conteúdo no prazo acordado com o criador. Recomendamos dividir o pagamento: 50% no fechamento do pedido e 50% na entrega.</Description>
                </DivColumn>
            </DivRow>
        </Container>
    )
}

export default SectionHowWork;