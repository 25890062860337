import styled from "styled-components";

export const Card = styled.div`
    background-color: var(--white);
    border: 1px solid var(--gray-50);
    border-radius: 10px;
    margin-inline: 8px;
    margin-bottom: 12px;
    height: 400px;
    width: 300px;
    padding: 10px;

    @media (max-width: 425px) {
        height: 380px;
        width: 100%;
        margin-inline: 0;
    }
`;

export const YellowBar = styled.div`
    background-color: var(--yellow);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 70px;
    margin-bottom: 12px;
    width: 100%;
`;

export const DivIMage = styled.div`
    border: 1px solid var(--black);
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 6px;
    height: 70px;
    width: 70px;

    img {
        height: 70px;
        width: 70px;
        object-fit: cover;
    }
`;

export const DivRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: -50px;
    height: 100px;
    width: 100%;
    padding: 8px;

    div {
        margin-top: auto;
    }

    div > svg:nth-of-type(2) {
      margin-left: 4px;
    }
`;

export const Creator = styled.h6`
  font-family: Archivo;
  font-size: 24px;
  font-weight: 800;

  @media (max-width: 425px) {
    font-size: 18px;
  }
`;

export const Cidade = styled.p`
  color: var(--yellow);
  font-family: Inconsolata;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 8px;
  max-width: 90%;

  @media (max-width: 425px) {
    font-size: 16px;
  }
`;

export const Sobre = styled.p`
    font-family: Inconsolata;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;

    @media (max-width: 425px) {
    font-size: 14px;
  }
`;

export const Description = styled.p`
    font-family: Inconsolata;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    @media (max-width: 425px) {
    font-size: 14px;
  }
`;