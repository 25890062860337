import { useEffect } from "react";
import Header from "../../components/Header/Header";
import InfluencerPic2 from "../../assets/user.webp";
import Message from "../../components/Message/Message";
import FileSquare from "../../assets/file-square.png";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../../providers/auth";
import { format, isAfter, parse, parseISO } from "date-fns";
import { QRCodeCanvas } from "qrcode.react";
import {
  Container,
  Content,
  TopContent,
  Proposition,
  PropositionContent,
  Button,
  Messages,
  NewMessage,
  FilesAttached,
  Input,
  MaskInput,
  Row,
  Select,
  HeaderPayment,
  HeaderSection,
  PixContainer,
  QRCodeContainer,
  ConfirmPixContainer,
  FooterModal,
  SectionModal,
} from "./styles";
import { useState } from "react";
import ReactModal from "react-modal";
import { BsFillCreditCardFill } from "react-icons/bs";
import { toast } from "react-toastify";
import { getChatHistory, uploadFile } from "../../services/chat";
import { socket, leaveRoom, addMessage } from "../../services/socket";
import { useClient } from "../../providers/user";
import { toReal } from "../../utils/currency";
import api from "../../services";
import { copyToClipboard } from "../../utils/helpers";
import { useCallback } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import { useRef } from "react";
import { CgAttachment } from "react-icons/cg";
import Footer from "../../components/Footer/Footer";
import Avatar from "../../assets/user.webp";
import { getSolicitation } from "../../providers/solicitation";
import { Loading } from "../ClientProfile/styles";

const _CURRENT_YEAR = new Date().getFullYear();

const Notifications = () => {
  const { auth } = useAuth();
  const { state } = useLocation();
  const { userData } = useClient();
  const history = useHistory();
  const INTERVAL = useRef();
  const transaoRef = useRef();
  const solicitationRef = useRef();

  const [solicitation, setSolicitation] = useState(null);
  const [visitor, setVisitor] = useState(false);
  const [modalCheckout, setModalCheckout] = useState(false);
  const [influencer, setInfluencer] = useState(null);
  const [room, setRoom] = useState(null);
  const [idSala, setIdSala] = useState();
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [valueMessage, setValueMessage] = useState("");
  const [fileMessage, setFileMessage] = useState({ type: "", file: "" });
  const [paymentType, setPaymentType] = useState("credit");
  const [checkoutPayload, setCheckoutPayload] = useState({ parcelas: "1" });
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [qrCodeData, setQrCodeData] = useState(null);
  const [shouldOpenModal, setShouldOpenModal] = useState(false);

  useEffect(() => {
    if (!auth) {
      const url = window.location.href;
      const partesDaURL = url.split("/");

      const idSolicitation = partesDaURL[partesDaURL.length - 1];
      if (idSolicitation) {
        getSolicitationId(idSolicitation);
        setVisitor(true);
        // buscar solicitação pelo ID
      } else {
        return history.push("/");
      }
    }

    return () => {
      clearInterval(INTERVAL.current);
      INTERVAL.current = null;
      transaoRef.current = null;
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const enterRoom = () => {
        // Lógica para entrar na sala
        if (state && state.idSala) {
            setIdSala(state.idSala);
            getSolicitationId(
                state.solicitation.idSolicitacao
                    ? state.solicitation.idSolicitacao
                    : state.solicitation.id
            );

            scrollbar();
            // Emitir evento 'enter-room' para entrar na sala
            socket.emit("enter-room", { idSala: state.idSala }); // Adicione esta linha

            // Escutar por mensagens
            socket.on("message", (valueMessage) => {
                setMessages((currentMessages) => [...currentMessages, valueMessage]);
                scrollbar();
            });

            getLastMessage(state.idSala);

            return () => leaveRoom({ idSala: state.idSala });
        } else {
            if (auth && !visitor) {
                const { cliente, id, influencidor } = state.solicitation;
                const room_id = Number(`${cliente.id}${influencidor.id}${id}`);
                setIdSala(room_id);
                setInfluencer(influencidor);
                setSolicitation(state.solicitation);

                scrollbar();
                // Emitir evento 'enter-room' para entrar na sala
                socket.emit("enter-room", { idSala: room_id }); // Adicione esta linha

                // Escutar por mensagens
                socket.on("message", (valueMessage) => {
                    setMessages((currentMessages) => [...currentMessages, valueMessage]);
                    scrollbar();
                });

                getLastMessage(room_id);

                return () => leaveRoom({ idSala: room_id });
            }
        }
    };

    // Chamar a função enterRoom ao montar o componente
    enterRoom();

    // Remover os listeners ao desmontar o componente
    return () => {
        socket.off("message");
        socket.off("enter-room");
    };
  }, []);

  // useEffect(() => {
  //   if (!valueMessage && room) {
  //     const { room_id } = room
  //     getLastMessage(room_id)
  //   }
  // }, [valueMessage, loading])

  // useEffect(() => {
  //   scrollbar();
  //   socket.on("message", (valueMessage) => {
  //     setMessages((currentMessages) => [...currentMessages, valueMessage]);
  //     scrollbar();
  //   });

  //   getLastMessage(idSala);

  //   return () => leaveRoom({ idSala: idSala });
  // }, []);

  useEffect(() => {
    if (fileMessage.file) {
      handleSubmitUpload();
    }
  }, [fileMessage]);

  function scrollbar() {
    const el = document.getElementById("chat-feed");
    if (el) {
      el.scrollTop = el.scrollHeight;
    }
  }

  const getLastMessage = async (room_id) => {
    try {
      const resp = await getChatHistory(room_id, "");
      if (resp.length > 0) {
        setMessages(resp);
        scrollbar();
      } else {
        setMessages([]);
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };

  function compare(a, b) {
    if (a.lastMessage > b.lastMessage) {
      return -1;
    }
    if (a.lastMessage < b.lastMessage) {
      return 1;
    }
    return 0;
  }

  const handleMessage = (value) => {
    setValueMessage(value);
  };

  const handleFileMessage = (type, value) => {
    let _file = value[0];
    setFileMessage({
      ...fileMessage,
      type:
        type === "media" &&
        (_file.type === "video/mp4" || _file.type === "video/quicktime")
          ? "document"
          : type,
      file: _file,
    });
  };

  const getSolicitationId = async (id) => {
    try {
      const result = await getSolicitation(id, auth);
      if (result) {
        const { influencidor } = result;
        setInfluencer(influencidor);
        setSolicitation(result);
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };

  const handleSubmitUpload = async () => {
    setLoading(true);
    try {
      const result = await uploadFile(idSala, fileMessage.file);
      if (result) {
        const message = {
          text: fileMessage.file.name,
          type: fileMessage.type,
          url: result.result[0].Location,
          idSala: idSala,
          idJob: solicitation.id,
          sender: {
            id: solicitation.cliente.id,
            email: solicitation.cliente.email,
            nome: solicitation.cliente.nome,
            foto: solicitation.cliente.profile_picture,
            tipo: 0,
          },
        };

        addMessage(message);
        handleCloseModal();
        setLoading(false);
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
      handleCloseModal();
      setLoading(false);
      toast.error("Ocorreu um erro ao enviar arquivo");
    }
  };

  function handleCloseModal() {
    setShouldOpenModal(false);
    setFileMessage({
      ...fileMessage,
      type: "",
      file: "",
    });
  }

  const handleSendMessage = useCallback(
    (event) => {
      if (event) {
        event.preventDefault();
      }
      if (valueMessage.trim().length === 0) {
        return {
          text: "validMessageError",
        };
      }

      // type: 0 para cliente e 1 para influencer

      const message = {
        text: valueMessage,
        type: "text",
        url: "",
        idSala: idSala,
        idJob: solicitation.id,
        sender: {
          id: solicitation.cliente.id,
          email: solicitation.cliente.email,
          nome: solicitation.cliente.nome,
          foto: solicitation.cliente.profile_picture,
          tipo: 0,
        },
      };
      setValueMessage("");
      addMessage(message);
    },
    [valueMessage]
  );

  function handleChangeCheckout(name, value) {
    setCheckoutPayload({ ...checkoutPayload, [name]: value });
  }

  function validCheckout() {
    const { cpf, cvv, descricao, exp_mes, exp_ano, nome, numero, parcelas } =
      checkoutPayload;
    if ((cpf, cvv, descricao, exp_mes, exp_ano, nome, numero, parcelas)) {
      return true;
    }
    return false;
  }

  async function buscarTransacao(idSolicitacao) {
    try {
      const response = await api.get(
        "api/v1/transacao/?solicitacao=" + idSolicitacao,
        {
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        }
      );
      const { data } = response;
      if (data.results[0]) {
        const { expiracao } = data.results[0];

        if (!isAfter(new Date(), new Date(expiracao))) {
          transaoRef.current = data.results[0];
          setQrCodeData(data.results[0]);
          INTERVAL.current = setInterval(() => watchPaymentStatus(), 2000);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function handleSubmit() {
    if (validCheckout()) {
      setLoadingPayment(true);
      const { exp_mes } = checkoutPayload;
      const _month = exp_mes < 10 ? "0" + exp_mes : exp_mes.toString();

      const _payload = {
        ...checkoutPayload,
        exp_mes: _month,
        valor: Number(solicitation.valor),
        descricao: "Pagamento pelo serviço na plataforma Boraver Influence",
        solicitacao: solicitation.id,
        cliente: solicitation.cliente,
      };

      try {
        const response = await api.post(
          "api/v1/transacao/checkout/",
          _payload,
          {
            headers: {
              Authorization: auth ? `Bearer ${auth}` : null,
            },
          }
        );
        setModalCheckout(false);
        setLoadingPayment(false);
        toast.success("Pagamento Realizado");
        setSolicitation({ ...solicitation, status: "Pago Aguardando Serviço" });
      } catch (error) {
        console.error(error);
        setLoadingPayment(false);
        toast.error("Ocorreu um erro no pagamento");
      }
    } else {
      toast.error("Informe os campos válidos no checkout");
    }
  }

  async function handlePixSubmit() {
    setLoadingPayment(true);

    const _payload = {
      nome: userData ? userData.nome : solicitation.nome_empresa,
      valor:
        Number(solicitation.valor) +
        (solicitation.exclusividade
          ? Number(solicitation.valor_exclusividade)
          : 0),
      descricao: "Pagamento pelo serviço na plataforma Boraver Influence",
      solicitacao: solicitation.id,
      cliente: solicitation.cliente,
      email_cliente: solicitation.email_cliente,
      tipo_solicitacao: solicitation.tipo_solicitacao,
    };

    try {
      const response = await api.post(
        "api/v1/transacao/checkout-pix/",
        _payload,
        {
          headers: {
            Authorization: auth ? `Bearer ${auth}` : null,
          },
        }
      );
      const { data } = response;
      transaoRef.current = data;
      setQrCodeData({ ...data });
      setLoadingPayment(false);
      INTERVAL.current = setInterval(() => watchPaymentStatus(), 2000);
    } catch (error) {
      console.error(error);
      toast.error("Ocorreu um erro no pagamento");
      setLoadingPayment(false);
    }
  }

  const watchPaymentStatus = async () => {
    try {
      const response = await api.get(
        "api/v1/transacao/" + transaoRef.current.id + "/",
        {
          headers: {
            Authorization: auth ? `Bearer ${auth}` : null,
          },
        }
      );

      const { data } = response;
      if (data.status === 3) {
        clearInterval(INTERVAL.current);
        INTERVAL.current = null;
        if (solicitation) {
          setSolicitation({
            ...solicitation,
            status: "Pago Aguardando Serviço",
          });
        } else if (solicitationRef.current) {
          setSolicitation({
            ...solicitationRef.current,
            status: "Pago Aguardando Serviço",
          });
        }
        setQrCodeData({ ...transaoRef.current, pago: true });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderDateExpiration = (date) => {
    try {
      const _date = format(parseISO(date), "dd/MM/yyyy HH:mm:ss");
      return _date;
    } catch (error) {
      return date;
    }
  };

  const handleCopyCode = () => {
    copyToClipboard(qrCodeData.qr_code);
    toast.success("Código Pix Copiado");
  };

  function handleDateInicio(value) {
    try {
      return format(new Date(value), "dd/MM/yyyy");
    } catch (error) {
      return value;
    }
  }

  function calculaValorTotal() {
    const {valor, exclusividade} = {...solicitation}

    const vSolicitation = valor ? valor : 0;
    const vExclusividade = exclusividade ? exclusividade : 0;

    const total = vSolicitation + vExclusividade;

    return total.replace('.', ',');
  }

  return (
    <Container>
      <Content>
        <TopContent>
          <div className="notificationProfilePic">
            <img
              src={
                influencer && influencer.profile_picture
                  ? influencer.profile_picture
                  : InfluencerPic2
              }
              alt=""
            />
            {/* <span>Ver perfil do influenciador</span> */}
          </div>
          <div className="notificationProfileName">
            <h2>{influencer && influencer.nomecompleto 
            ? influencer.nomecompleto 
            : influencer && influencer.nome
            ? influencer.nome
            : null}</h2>
            <p>Influenciador(a)</p>
          </div>
          {solicitation &&
          solicitation.status === "Pago Aguardando Serviço" ? null : (
            <button onClick={() => setModalCheckout(true)}>
              Fazer Pagamento
            </button>
          )}
        </TopContent>
        {solicitation && solicitation.sobre_influencer ? (
          <Container>
            <h2>Por que sou a escolha certa?</h2>
            <p>{solicitation.sobre_influencer}</p>
          </Container>
        ): null}
        <Proposition>
          <h2>Proposta realizada</h2>
          <PropositionContent>
            <div>
              <p>Valor da oferta</p>
              <span>
                R${" "}
                {solicitation ? calculaValorTotal() : "0,00"}
              </span>
            </div>
            {/* <div>
              <p>Valor exclusividade</p>
              <span>
                R${" "}
                {solicitation && solicitation.exclusividade
                  ? solicitation.valor_exclusividade.replace(".", ",")
                  : "0,00"}
              </span>
            </div> */}
            <div>
              <p>Período para entrega</p>
              <span>{solicitation ? handleDateInicio(solicitation.inicio) : null}</span>
            </div>
            <div className="propositionStatus">
              <p>
                Status da proposta:{" "}
                <span className="propostitionStatusResponse">
                  {solicitation
                    ? solicitation.status === "Em Andamento"
                      ? "Aguardando Pagamento"
                      : solicitation.status
                    : null}
                </span>{" "}
              </p>
            </div>
          </PropositionContent>
        </Proposition>
        {!visitor ? (
          <Messages id="chat-feed">
            <h2>Minhas mensagens</h2>
            <div className="chat">
              {messages.length === 0 ? (
                <div className="notMessage">Sem mensagens</div>
              ) : (
                messages.map((item) => (
                  <Message
                    key={item._id}
                    from={item.sender.tipo === 0 ? "sender" : "receiver"}
                    imageInflu={item.sender.foto ? item.sender.foto : Avatar}
                    message={item}
                  />
                  // null
                ))
              )}
            </div>
            <form onSubmit={handleSendMessage}>
              <NewMessage>
                <div className="newMessageContent">
                  <div className="input-group">
                    <input
                      type="text"
                      className="newMessageInput"
                      placeholder="Digite aqui sua mensagem"
                      value={valueMessage ? valueMessage : ""}
                      onChange={({ target }) => handleMessage(target.value)}
                    />
                    <button
                      type="button"
                      onClick={() => setShouldOpenModal(true)}
                    >
                      <CgAttachment />
                    </button>
                  </div>
                  <button
                    type="button"
                    className="send"
                    onClick={(ev) => handleSendMessage(ev)}
                  >
                    Enviar
                  </button>
                </div>
              </NewMessage>
            </form>
          </Messages>
        ) : null}
        {/* <FilesAttached>
          <h2>Arquivos anexados</h2>
          <div className="filesDiv">
            // <div>
            //  <label htmlFor="teste">
            //    <input type="file" name="teste" id="teste" accept='.jpeg'/>
            //  </label>
            // </div>
            <div>
              <img src={FileSquare} alt="" />
            </div>
            <div>
              <img src={FileSquare} alt="" />
            </div>
            <div>
              <img src={FileSquare} alt="" />
            </div>
          </div>
        </FilesAttached> */}
      </Content>
      <ReactModal
        isOpen={modalCheckout}
        onRequestClose={() => setModalCheckout(false)}
        style={{
          content: {
            top: "40%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
            display: "inline-grid",
            width: "50%",
          },
        }}
      >
        <HeaderPayment>
          <HeaderSection>
            <input
              type="radio"
              id="credit"
              name="radio"
              checked={paymentType === "credit"}
              onChange={({ target }) => setPaymentType(target.id)}
            />
            <BsFillCreditCardFill
              className="icon"
              size={18}
            ></BsFillCreditCardFill>
            <label htmlFor="credit">Cartão</label>
          </HeaderSection>
          <HeaderSection>
            <input
              type="radio"
              id="pix"
              name="radio"
              checked={paymentType === "pix"}
              onChange={({ target }) => setPaymentType(target.id)}
            />
            <img className="img" src={require("../../assets/pix-icone.png")} />
            <label htmlFor="pix">Pix</label>
          </HeaderSection>
        </HeaderPayment>
        {paymentType === "credit" ? (
          <>
            <Input
              placeholder="Número do Cartão"
              onChange={({ target }) =>
                handleChangeCheckout("numero", target.value)
              }
            />
            <Input
              placeholder="Nome Titular do Cartão"
              onChange={({ target }) =>
                handleChangeCheckout("nome", target.value)
              }
            />
            <MaskInput
              placeholder="CPF Titula do Cartão"
              mask={"999.999.999-99"}
              onChange={({ target }) =>
                handleChangeCheckout("cpf", target.value)
              }
            />
            <Row>
              <Select
                onChange={({ target }) =>
                  handleChangeCheckout("exp_mes", target.value)
                }
              >
                <option hidden>MÊS</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
                <option>11</option>
                <option>12</option>
              </Select>
              <Select
                onChange={({ target }) =>
                  handleChangeCheckout("exp_ano", target.value)
                }
              >
                <option hidden>ANO</option>
                {Array.from(Array(15), (_, index) => (
                  <option
                    key={_CURRENT_YEAR + index}
                    value={_CURRENT_YEAR + index}
                  >
                    {_CURRENT_YEAR + index}
                  </option>
                ))}
              </Select>
              <Input
                placeholder="CVV"
                onChange={({ target }) =>
                  handleChangeCheckout("cvv", target.value)
                }
              />
            </Row>
            {solicitation ? (
              <Select
                onChange={({ target }) =>
                  handleChangeCheckout("parcelas", target.value)
                }
              >
                <option value={1}>
                  1x de{" "}
                  {toReal(
                    Number(solicitation.valor) +
                      (solicitation.exclusividade
                        ? Number(solicitation.valor_exclusividade)
                        : 0)
                  )}
                </option>
                <option value={2}>
                  2x de{" "}
                  {toReal(
                    (Number(solicitation.valor) +
                      (solicitation.exclusividade
                        ? Number(solicitation.valor_exclusividade)
                        : 0)) /
                      2
                  )}
                </option>
                <option value={3}>
                  3x de{" "}
                  {toReal(
                    (Number(solicitation.valor) +
                      (solicitation.exclusividade
                        ? Number(solicitation.valor_exclusividade)
                        : 0)) /
                      3
                  )}
                </option>
                <option value={4}>
                  4x de{" "}
                  {toReal(
                    (Number(solicitation.valor) +
                      (solicitation.exclusividade
                        ? Number(solicitation.valor_exclusividade)
                        : 0)) /
                      4
                  )}
                </option>
                <option value={5}>
                  5x de{" "}
                  {toReal(
                    (Number(solicitation.valor) +
                      (solicitation.exclusividade
                        ? Number(solicitation.valor_exclusividade)
                        : 0)) /
                      5
                  )}
                </option>
                <option value={6}>
                  6x de{" "}
                  {toReal(
                    (Number(solicitation.valor) +
                      (solicitation.exclusividade
                        ? Number(solicitation.valor_exclusividade)
                        : 0)) /
                      6
                  )}
                </option>
                <option value={7}>
                  7x de{" "}
                  {toReal(
                    (Number(solicitation.valor) +
                      (solicitation.exclusividade
                        ? Number(solicitation.valor_exclusividade)
                        : 0)) /
                      7
                  )}
                </option>
                <option value={8}>
                  8x de{" "}
                  {toReal(
                    (Number(solicitation.valor) +
                      (solicitation.exclusividade
                        ? Number(solicitation.valor_exclusividade)
                        : 0)) /
                      8
                  )}
                </option>
                <option value={9}>
                  9x de{" "}
                  {toReal(
                    (Number(solicitation.valor) +
                      (solicitation.exclusividade
                        ? Number(solicitation.valor_exclusividade)
                        : 0)) /
                      9
                  )}
                </option>
                <option value={10}>
                  10x de{" "}
                  {toReal(
                    (Number(solicitation.valor) +
                      (solicitation.exclusividade
                        ? Number(solicitation.valor_exclusividade)
                        : 0)) /
                      10
                  )}
                </option>
                <option value={11}>
                  11x de{" "}
                  {toReal(
                    (Number(solicitation.valor) +
                      (solicitation.exclusividade
                        ? Number(solicitation.valor_exclusividade)
                        : 0)) /
                      11
                  )}
                </option>
                <option value={12}>
                  12x de{" "}
                  {toReal(
                    (Number(solicitation.valor) +
                      (solicitation.exclusividade
                        ? Number(solicitation.valor_exclusividade)
                        : 0)) /
                      12
                  )}
                </option>
              </Select>
            ) : null}
            <Button isDisabled={loadingPayment} onClick={handleSubmit}>
              Fazer Pagamento
            </Button>
          </>
        ) : (
          <PixContainer>
            <p>
              <strong>
                {userData ? userData.nome : solicitation.nome_empresa}
              </strong>
            </p>
            <p>
              <strong>valor: </strong>{" "}
              {toReal(
                Number(solicitation.valor) +
                  (solicitation.exclusividade
                    ? Number(solicitation.valor_exclusividade)
                    : 0)
              )}
            </p>

            {qrCodeData && qrCodeData.pago === true ? (
              <ConfirmPixContainer>
                <AiFillCheckCircle color="green" size={85} />
                <p>
                  <strong>Pagamento Confirmado</strong>
                </p>
              </ConfirmPixContainer>
            ) : qrCodeData && qrCodeData.pago !== true ? (
              <>
                <p>
                  <strong>Código PIX válido até: </strong>
                  {renderDateExpiration(qrCodeData.expiracao)}
                </p>
                <QRCodeContainer>
                  <QRCodeCanvas
                    fgColor="#3c2e54"
                    value={qrCodeData.qr_code}
                    size={280}
                  />
                </QRCodeContainer>
                <Button onClick={handleCopyCode}>Copiar Código Pix</Button>
              </>
            ) : (
              <Button isDisabled={loadingPayment} onClick={handlePixSubmit}>
                Gerar QR code
              </Button>
            )}
          </PixContainer>
        )}
      </ReactModal>
      <ReactModal
        isOpen={shouldOpenModal}
        style={{
          content: {
            top: "40%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
            display: "inline-grid",
            width: "30%",
          },
        }}
      >
        <SectionModal>
          <div>
            <input
              type="file"
              name="image"
              id="image"
              accept=".png, .jpeg, .jpg, .webp, .mp4, .mov"
              onChange={({ target }) =>
                handleFileMessage("media", target.files)
              }
            />
            <label htmlFor="image">Foto ou Vídeo</label>
          </div>
          <div>
            <input
              type="file"
              name="document"
              id="document"
              accept=".pdf, .doc, .docx, .txt, .ppt, .pptx"
              onChange={({ target }) =>
                handleFileMessage("document", target.files)
              }
            />
            <label htmlFor="document">Documento</label>
          </div>
        </SectionModal>
        <FooterModal>
          {loading ? <Loading /> : null}
          <button
            type="button"
            disabled={loading}
            onClick={() => handleCloseModal()}
          >
            Cancelar
          </button>
        </FooterModal>
      </ReactModal>
    </Container>
  );
};
export default Notifications;
