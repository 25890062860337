import { Container, DivColumn } from "./styles";
import Button from "../Button/Button";
import CardFAQ from "../CardFaq/CardFaq";
import { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Faq = () => {
    const history = useHistory();
    const [questions, setQuestions] = useState([
        {
            id: 1,
            title: "Acesso para empresas é gratuito?",
            response: "Sim, as marcas têm acesso gratuito e podem explorar todos os criadores disponíveis na plataforma Boraver.",
            status: true,
        },
        {
            id: 2,
            title: "Como funciona o processo de seleção de criadores de conteúdo na plataforma Boraver?",
            response: "Sim, as marcas têm acesso gratuito e podem explorar todos os criadores disponíveis na plataforma Boraver.",
            status: false,
        },
        {
            id: 3,
            title: "A Boraver oferece suporte para criar campanhas de UGC?",
            response: "Sim, as marcas têm acesso gratuito e podem explorar todos os criadores disponíveis na plataforma Boraver.",
            status: false,
        },
        {
            id: 4,
            title: "Como a Boraver garante a qualidade dos criadores de conteúdo na plataforma?",
            response: "Sim, as marcas têm acesso gratuito e podem explorar todos os criadores disponíveis na plataforma Boraver.",
            status: false,
        },
    ]);

    const alterStatus = (id) => {
        const _questions = questions.map(item => {
            if (item.id === id) {
                item.status = !item.status;
            } else if (item.status === true) {
                item.status = false;
            }
            return item;
        })

        setQuestions(_questions);
    }

    const handleNavigation = (path) => {
        return history.push(path);
      };

    return (
        <Container>
            <DivColumn wide="40%">
                <h2>A <span>Escolha #1</span> Para Marcas e Criadores de Conteúdo</h2>
                <p className="paragraph">Onde marcas e criadores prosperam. Nossa rede diversificada, abordagem transparente e suporte inabalável geram colaborações impactantes em todas as categorias.</p>
                <Button
                 setBackgroundColor="var(--yellow)" 
                 setColor="var(--black-50)"
                 onClick={() => handleNavigation('/lista-creators')}
                 >Encontre Um Creator</Button>
            </DivColumn>
            <DivColumn wide="56%">
                {questions.map(question => (
                    <CardFAQ 
                        id={question.id}
                        title={question.title}
                        response={question.response}
                        status={question.status}
                        key={question.id}
                        alterStatus={alterStatus}
                    />
                ))}
            </DivColumn>
        </Container>
    )
}

export default Faq;