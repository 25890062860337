import styled from "styled-components";

export const Container = styled.div`
  background-color: var(--white-50);
  position: relative;
  display: flex;
  margin-left: auto;
  min-height: 90dvh;
  width: 100%;
  padding: 50px 100px;
`;

export const DivColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > h2 {
    color: var(--brightpurple);
    font-size: 26px;
    margin: 20px 20px 20px 4%;
  }

  .solicitationsType {
    width: 100%;

    display: flex;
    justify-content: center;

    & > button {
      margin: 0;
      border: 0.5px solid var(--gray);
      background: var(--purple);
      padding: 1rem;
      cursor: pointer;

      & > span {
        color: var(--white);
      }
    }

    & > .active {
      background: var(--brightpurple);
    }
  }
`;

export const DivRow = styled.div`
  display: flex;
`;

export const Card = styled.div`
  background-color: var(--white);
  border: 1px solid var(--graycard);
  border-radius: 10px;

  .influencerSolicitation {
    background-color: var(--white);
    border-bottom: 1px solid var(--graycard);
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px 14px;
    margin-bottom: 10px;
  }

  img {
    border: 1px solid var(--black);
    border-radius: 50%;
    margin-right: 10px;
    height: 70px;
    width: 70px;
    object-fit: cover;
  }

  & > h2 {
    color: var(--black);
    font-family: Inconsolata;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  & > h3 {
    color: var(--black);
    font-family: Inconsolata;
    font-weight: 400;
  }
`