import { useEffect } from "react";
import { useForm } from "react-hook-form"
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Input from '../../components/Input/Input'
import LoginPic from '../../assets/LoginPic.png'
import { Content, Form, LeftSide, RightSide } from "./styles";
import Button from "../../components/Button/Button";
import { useHistory } from "react-router-dom";
import api from "../../services";
import { toast } from "react-toastify";
import { useAuth } from "../../providers/auth";

const ResetRequest = () => {

  const { auth } = useAuth()

  const history = useHistory()

  const schema = yup.object().shape({
    nome: yup.string().min(1).required("Campo obrigatório"),
    email: yup.string().email("Email inválido!").required("Campo obrigatório!"),
    confirmEmail: yup.string().email("Email invalido").oneOf([yup.ref("email")], "Email's diferentes!").required("Campo obrigatório!")
  })

  const {register, handleSubmit, formState: {errors}} = useForm({resolver: yupResolver(schema)})

  const resetPass = (data) => {
    const emailSent = {email: data.email, nome: data.nome}

    api
        .post("/api/v3/resetpassword/", emailSent)
        .then((res) => {
            toast.success("Cheque sua caixa de e-mail")
            return history.push('/login')
        })
        .catch((res)=> toast.error("Alguma coisa deu errado, tente novamente!"))
  }

  const handleNavigation = (path) => {
    return history.push(path)
  }

  useEffect(() => {
    if(auth){
      return history.push("/dashboard")
    }
  }, [])

  return(
    <div>
      {/* <Header /> */}
      <Content>
        <LeftSide>
          <Form onSubmit={handleSubmit(resetPass)}>
            <h2>Confirme para resetar</h2>
            <Input 
              register={register}
              name="nome"
              label="Nome"
              placeholder="Digite seu nome"
              error={errors.nome?.message}
            />
            <Input 
              register={register}
              name="email"
              label="Email"
              placeholder="Digite seu email"
              error={errors.email?.message}
            />
            <Input 
              register={register}
              name="confirmEmail"
              label="Confirme o email"
              placeholder="Confirme o seu email"
              error={errors.confirmEmail?.message}
            />
            <span>Ainda não possui cadastro? Cadastre-se <a onClick={() => handleNavigation("/signup")}>aqui</a></span>
            <span>Lembrou sua senha? Clique <a onClick={() => handleNavigation("/login")}>aqui</a></span>
            <div className="btn-place">
              <Button 
                setBackgroundColor="var(--purple)"
                setColor="var(--white)"
                large
                type="submit"
              >
                Resetar
              </Button>
            </div>
          </Form>
        </LeftSide>
        <RightSide>
          <img src={LoginPic} alt="login-boraver" />
        </RightSide>
      </Content>
      {/* <Footer /> */}
    </div>
  )
}
export default ResetRequest