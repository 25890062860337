import React from "react";
import { Container, DivRow, Subtitle, Title } from "./styles";
import Cicatribem from "../../assets/Cicatribem.png";
import Ican from "../../assets/ican.png";
import Monlife from "../../assets/monlife.png";
import Gennezi from "../../assets/gennezi.png";

const SectionCompanys = () => {
    return (
        <Container>
            <Title>Empresas Parceiras</Title>
            <DivRow>
                <img className="image1" src={Cicatribem} height={66} width={200} />
                <img className="image2" src={Ican} height={64} width={200} />
                <img className="image3" src={Monlife} height={62} width={240} />
                <img className="image4" src={Gennezi} height={72} width={200} />
                {/* <img className="image5" src={Havaianas} height={90} width={200} /> */}
            </DivRow>
            <Subtitle>
                São mais de 200 empresas que já são parceiras do Boraver. Torne-se parceiro da nossa plataforma.
            </Subtitle>
        </Container>
    )
}

export default SectionCompanys;