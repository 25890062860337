import axios from "axios";
const currentUrl = window.location.href;

export let chatUrl = "";

if (currentUrl.includes(".com")) {
  chatUrl = "https://chat.boraverapp.com";
} else if (currentUrl.includes("devaribox.co")) {
  chatUrl = "https://boraverapp-chat.devari.com.br";
} else if (currentUrl.includes(":300")) {
  //change this if needed for local development
  chatUrl = "https://boraverapp-chat.devari.com.br";
  // chatUrl = "http://192.168.1.6:3005";
}

const chatProvider = axios.create({
  baseURL: `${chatUrl}/api/v1/`,
});

const _provider = axios.create({
  baseURL: "https://bucket.devari.com.br/api/v1/aws/s3-boraverapp/",
});

export async function getChats(id) {
  try {
    const resp = await chatProvider.get("chats/", {
      params: {
        query: id,
      },
    });

    return resp.data;
  } catch (error) {
    console.error("Erro: ", error);
  }
}

export async function getChat(clientId, status, email) {
  try {
    const params = {
      idCliente: clientId,
      email: email,
    }

    if (status === "Em Andamento") {
      params.status = ["Em Andamento", "Pago Aguardando Serviço"];
    } else {
      params.status = status;
    }

    const response = await chatProvider.get("chat/", {
      params: params,
    });

    // const chats = response.data.chatList;

    // const chat = chats.filter((responseChat) => {
    //   if (responseChat.room_id === `${clientId}${artistId}${solicitation}`) {
    //     return responseChat;
    //   }
    // })[0];

    // if (chat) {
    //   return chat;
    // }

    return response.data

    throw Error("No chat");
  } catch (err) {
    throw Error(err);
  }
}

export async function getChatHistory(room) {
  try {
    const response = await chatProvider.get("mensagem/", {
      params: {
        idSala: room,
      },
    });

    return response.data;
  } catch (err) {
    throw Error(err);
  }
}

export async function uploadFile(folder, file) {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await _provider.post(`${folder}/devariupload/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    throw Error(error);
  }
}
