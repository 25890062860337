import styled from "styled-components";

export const StepBox = styled.div`
  padding: 3rem 3rem 5rem 3rem;

  .section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    max-width: 100%;

    img {
      max-height: 450px;
      max-width: 300px;
    }
  }

  h3 {
    text-align: center;
    font-size: 32px;
    color: var(--purple);
    margin-bottom: 1rem;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;
