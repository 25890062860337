import React, { useCallback, useEffect, useState } from "react";
import { Container } from "./styles";
import { DivColumn } from "../ListMessages/styles";
import { DivBetween, DivImage, Title } from "../VitrineCreators/styles";
import { useClient } from "../../providers/user";
import ImgUser from "../../assets/user.webp";
import { getSolicitation } from "../../providers/solicitation";
import { getChatHistory, uploadFile } from "../../services/chat";
import socket from "socket.io-client/lib/socket";
import { addMessage, leaveRoom } from "../../services/socket";
import { useAuth } from "../../providers/auth";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";

const Messages = () => {
  const { auth } = useAuth();
  const { state } = useLocation();
  const { userData, clientId } = useClient();
  const [idSala, setIdSala] = useState();
  const [visitor, setVisitor] = useState(false);
  const [messages, setMessages] = useState([]);
  const [solicitation, setSolicitation] = useState(null);
  const [influencer, setInfluencer] = useState(null);
  const [valueMessage, setValueMessage] = useState("");
  const [fileMessage, setFileMessage] = useState({ type: "", file: "" });
  const [loading, setLoading] = useState(false);
  const [shouldOpenModal, setShouldOpenModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const enterRoom = () => {
      // Lógica para entrar na sala
      if (state && state.idSala) {
        setIdSala(state.idSala);
        getSolicitationId(
          state.solicitation.idSolicitacao
            ? state.solicitation.idSolicitacao
            : state.solicitation.id
        );

        scrollbar();
        // Emitir evento 'enter-room' para entrar na sala
        socket.emit("enter-room", { idSala: state.idSala }); // Adicione esta linha

        // Escutar por mensagens
        socket.on("message", (valueMessage) => {
          setMessages((currentMessages) => [...currentMessages, valueMessage]);
          scrollbar();
        });

        getLastMessage(state.idSala);

        return () => leaveRoom({ idSala: state.idSala });
      } else {
        if (auth && !visitor) {
          const { cliente, id, influencidor } = state?.solicitation;
          const room_id = Number(`${cliente.id}${influencidor.id}${id}`);
          setIdSala(room_id);
          setInfluencer(influencidor);
          setSolicitation(state?.solicitation);

          scrollbar();
          // Emitir evento 'enter-room' para entrar na sala
          socket.emit("enter-room", { idSala: room_id }); // Adicione esta linha

          // Escutar por mensagens
          socket.on("message", (valueMessage) => {
            setMessages((currentMessages) => [
              ...currentMessages,
              valueMessage,
            ]);
            scrollbar();
          });

          getLastMessage(room_id);

          return () => leaveRoom({ idSala: room_id });
        }
      }
    };

    // Chamar a função enterRoom ao montar o componente
    enterRoom();

    // Remover os listeners ao desmontar o componente
    return () => {
      socket.off("message");
      socket.off("enter-room");
    };
  }, []);

  const getSolicitationId = async (id) => {
    try {
      const result = await getSolicitation(id, auth);
      if (result) {
        const { influencidor } = result;
        setInfluencer(influencidor);
        setSolicitation(result);
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };

  function scrollbar() {
    const el = document.getElementById("chat-feed");
    if (el) {
      el.scrollTop = el.scrollHeight;
    }
  }

  const getLastMessage = async (room_id) => {
    try {
      const resp = await getChatHistory(room_id, "");
      if (resp.length > 0) {
        setMessages(resp);
        scrollbar();
      } else {
        setMessages([]);
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };

  const handleMessage = (value) => {
    setValueMessage(value);
  };

  const handleFileMessage = (type, value) => {
    let _file = value[0];
    setFileMessage({
      ...fileMessage,
      type:
        type === "media" &&
        (_file.type === "video/mp4" || _file.type === "video/quicktime")
          ? "document"
          : type,
      file: _file,
    });
  };

  const handleSubmitUpload = async () => {
    setLoading(true);
    try {
      const result = await uploadFile(idSala, fileMessage.file);
      if (result) {
        const message = {
          text: fileMessage.file.name,
          type: fileMessage.type,
          url: result.result[0].Location,
          idSala: idSala,
          idJob: solicitation.id,
          sender: {
            id: solicitation.cliente.id,
            email: solicitation.cliente.email,
            nome: solicitation.cliente.nome,
            foto: solicitation.cliente.profile_picture,
            tipo: 0,
          },
        };

        addMessage(message);
        handleCloseModal();
        setLoading(false);
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
      handleCloseModal();
      setLoading(false);
      toast.error("Ocorreu um erro ao enviar arquivo");
    }
  };

  function handleCloseModal() {
    setShouldOpenModal(false);
    setFileMessage({
      ...fileMessage,
      type: "",
      file: "",
    });
  }

  const handleSendMessage = useCallback(
    (event) => {
      if (event) {
        event.preventDefault();
      }
      if (valueMessage.trim().length === 0) {
        return {
          text: "validMessageError",
        };
      }

      // type: 0 para cliente e 1 para influencer

      const message = {
        text: valueMessage,
        type: "text",
        url: "",
        idSala: idSala,
        idJob: solicitation.id,
        sender: {
          id: solicitation.cliente.id,
          email: solicitation.cliente.email,
          nome: solicitation.cliente.nome,
          foto: solicitation.cliente.profile_picture,
          tipo: 0,
        },
      };
      setValueMessage("");
      addMessage(message);
    },
    [valueMessage]
  );

  return (
    <Container>
      <DivColumn>
        <DivBetween style={{ marginBottom: 14 }}>
          <Title>Mensagens</Title>
          <DivImage>
            <img
              src={
                userData && userData.profile_picture
                  ? userData.profile_picture
                  : ImgUser
              }
              alt="Imagem do usuário"
              height={60}
              width={60}
            />
          </DivImage>
        </DivBetween>
      </DivColumn>
    </Container>
  );
};

export default Messages;
