import styled from "styled-components";

export const Container = styled.div`
  background-color: var(--white-50);
  display: flex;
  flex-direction: column;
  margin-left: auto;
  min-height: 90dvh;
  width: 82%;
  padding: 50px 30px;
`;
