import styled from "styled-components";

export const Container = styled.div`
  margin-top: 20px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
  max-width: 100%;

  h3 {
    text-align: center;
    font-size: 32px;
    color: var(--purple);
  }
`;

export const Content = styled.div`
  width: 90vw;
  display: flex;
  justify-content: space-around;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const ImageBox = styled.div`
  max-width: 40vw;
  display: flex;
  align-items: center;
  img {
    width: 100%;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  ul {
    line-height: 2.5;
    margin: 2rem 0rem;
    max-width: 450px;
  }

  li {
    list-style: none;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: var(--purple);
    margin-bottom: 2rem;
  }
`;
