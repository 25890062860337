import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import LayoutWrapper from '../components/LayoutWrapper/LayoutWrapper';
import Dashboard from '../pages/Dashboard/Dashboard';
import NewContactUs from '../pages/NewContactUs/NewContactUs';
import InfluencerDetails from '../pages/InfluencerDetails/InfluencerDetails';
import ClientProfile from '../pages/ClientProfile/ClientProfile';
import Notifications from '../pages/Notifications/Notifications';
import Payment from '../pages/Payment/Payment';
import SolicitationsList from '../pages/Solicitations/Solicitations';
import MyPackages from '../pages/MyPackages/Mypackages';
import NewCampaign from '../pages/NewCampaign/NewCampaign';
import ChangePass from '../pages/ChangePass/ChangePass';
import Home from '../pages/Home/Home';
import Signup from '../pages/Signup/Signup';
import Login from '../pages/Login/Login';
import ResetRequest from '../pages/ResetRequestPage/ResetRequestPage';
import { useAuth } from '../providers/auth';
import { useClient } from '../providers/user';
import ListMessages from '../pages/ListMessages/ListMessages';
import ListCreators from '../pages/ListCreators/ListCreators';
import Register from '../pages/Register';
import VitrineCreators from '../pages/VitrineCreators';
import MyCampaign from '../pages/MyCampaign/MyCampaign';
import Messages from '../pages/Messages/Messages';
import ConfirmRegister from '../pages/ConfirmRegister';
import LinksCreator from '../pages/LinksCreator';


const AppRouter = () => {
  const { setUserId } = useClient();
  const { setAuth, auth } = useAuth();
  const history = useHistory();
  const [linkConvite, setLinkConvite] = useState(false);

  const [loading, setLoading] = useState(true);
  const [visibleSidebar, setVisibleSidebar] = useState(true);

  useEffect(() => {
    const localItem = localStorage.getItem("@boraver");
    if (localItem) {
      const data = JSON.parse(localItem);
      setAuth(data.token);
      setUserId(data.user.id);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (auth) {
      const currentPath = window.location.href;
      const partesDaURL = currentPath.split("/");

      if (!partesDaURL.includes("influencerdetails")) {
        history.push("/dashboard");
      }
    }
  }, [auth, history]);
  

  useEffect(() => {
    const url = window.location.href;
    const partesDaURL = url.split("/");

    if (partesDaURL.includes("influencerdetails") && !auth) {
      setLinkConvite(true);
    } else {
      setLinkConvite(false);
    }
  }, []);

  const toggleSidebar = () => setVisibleSidebar(!visibleSidebar);

  return (
    <Router>
      {!loading ? (
        <Switch>
          {auth ? (
            <LayoutWrapper isAuth={true} toggleSidebar={toggleSidebar} visibleSidebar={visibleSidebar} showDivZap={!linkConvite}>
              <Switch>
                <Route exact path="/dashboard" component={Dashboard} />
                <Route exact path="/vitrine-creators" component={VitrineCreators} />
                <Route exact path="/contactus" component={NewContactUs} />
                <Route exact path="/influencerdetails/:idInfluencer" component={InfluencerDetails} />
                <Route exact path="/clientprofile" component={ClientProfile} />
                <Route exact path="/notifications" component={Notifications} />
                <Route exact path="/payment" component={Payment} />
                <Route exact path="/solicitations" component={SolicitationsList} />
                <Route exact path="/campaign" component={MyCampaign} />
                <Route exact path="/packages" component={MyPackages} />
                <Route exact path="/new-campaign" component={NewCampaign} />
                <Route exact path="/messages" component={ListMessages} />
                <Route exact path="/chat" component={Messages} />
                <Route exact path="/change-pass/api/v3/reset/:uidb/:token" component={ChangePass} />
                <Redirect to="/dashboard" />
              </Switch>
            </LayoutWrapper>
          ) : (
            <LayoutWrapper isAuth={false} showDivZap={!linkConvite}>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/lista-creators" component={ListCreators} />
                <Route exact path="/influencerdetails/:idInfluencer" component={InfluencerDetails} />
                <Route exact path="/notifications/:idSolicitation" component={Notifications} />
                <Route path="/signup" component={Register} />
                <Route path="/login" component={Login} />
                <Route path="/links-creator" component={LinksCreator} />
                <Route path="/confirm-register" component={ConfirmRegister} />
                <Route exact path="/reset-request" component={ResetRequest} />
                <Redirect to="/" />
              </Switch>
            </LayoutWrapper>
          )}
        </Switch>
      ) : null}
    </Router>
  );
};

export default AppRouter;
