import React, { useEffect, useState } from "react"
import { useAuth } from "../../providers/auth";
import { useClient } from "../../providers/user";
import { getChat, getChatHistory } from "../../services/chat";
import { useHistory } from "react-router-dom";
import { format, formatDistance } from "date-fns";
import Logo from "../../assets/user.webp";
import { DivColumn, Container, Card, DivRow } from "./styles";
import { createChatRoom, enterRoom } from "../../services/socket";
import { DivBetween, DivImage, Title } from "../VitrineCreators/styles";
import ImgUser from "../../assets/user.webp";
import { ptBR } from "date-fns/locale";

const ListMessages = () => {
    const { auth } = useAuth();
    const { userData, clientId } = useClient();
    const history = useHistory();
    const [chatProgress, setChatProgress] = useState([]);
    const [chatWaiting, setChatWaiting] = useState([]);
    const [chatRefused, setChatRefused] = useState([]);
    const [sectionActive, setSectionActive] = useState("Em Andamento");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (clientId) getSalas();
    }, [])

    const getSalas = async () => {
        try {
            const result = await getChat(clientId, sectionActive, userData.email);
            if (result) {
                if (sectionActive === "Em Andamento") {
                    setChatProgress([...result]);
                } else if (sectionActive === "recusado") {
                    setChatRefused([...result]);
                } else {
                    setChatWaiting([...result]);
                }
            }
        } catch (error) { }
    };

    async function handleChatClick(value) {
        if (value.status !== "Nova Oferta" && value.status !== "Concluído") {
            setLoading(true);
            const { idSala } = value;
            const room_id = idSala
                ? idSala
                : Number(`${value.cliente.id}${value.influencidor.id}${value.id}`);
            enterRoom({ idSala: room_id });
            handleNavigate(value, idSala);
            setLoading(false);
        }
    }

    function handleDateInicio(value) {
        try {
            return format(new Date(value), "dd/MM/yyyy");
        } catch (error) {
            return value;
        }
    }

    const renderRooms = (roomChat) => {

        return roomChat.map((chat, index) => (
            <div className="influencerSolicitation" key={index} onClick={() => handleChatClick(chat)}>
                <DivRow>
                    <img
                        src={chat.fotoJober ? chat.fotoJober : Logo}
                        alt="client-profilepic"
                    />
                    <div>
                        <h2>{chat.nomeJober}</h2>
                        <p></p>
                    </div>
                </DivRow>
                <p>{chat?.ultimaMensagem?.data 
                ? formatDistance(
                    new Date(chat.ultimaMensagem.data),
                    new Date(),
                    {
                    addSuffix: true,
                    includeSeconds: true,
                    locale: ptBR,
                    }
                ) : ''}</p>
                
                {/* {chat.status !== "Nova Oferta" && chat.status !== "Concluído" ? (
                    <button
                        className="primary"
                        disabled={loading}
                        onClick={() => handleChatClick(chat)}
                    >
                        Chat
                    </button>
                ) : (
                    <div className="emptySpace"></div>
                )} */}
            </div>
        ));
    };

    function handleChatJob(value) {
        const { id, cliente, influencidor } = value;
        const room_id = `${cliente}${influencidor.id}${id}`;
        const getMessage = async () => {
            const resp = await getChatHistory(room_id, "");
            if (resp.length > 0) {
                enterRoom(room_id);
                handleNavigate(value, resp);
            } else {
                createChatRoom(room_id);
                handleNavigate(value, room_id);
            }
        };
        getMessage();
    }

    const handleNavigate = (solicitation, idSala) => {
        history.push({
            pathname: "/chat",
            state: { solicitation, idSala },
        });
    };

    return (
        <Container>
            <DivColumn style={{ margin: "0 auto", width: "90%" }}>
                <DivBetween style={{marginBottom: 14}}>
                    <Title>Mensagens</Title>
                    <DivImage>
                        <img
                        src={
                            userData && userData.profile_picture
                            ? userData.profile_picture
                            : ImgUser
                        }
                        alt="Imagem do usuário"
                        height={60}
                        width={60}
                        />
                    </DivImage>
                </DivBetween>

                <DivColumn style={{width: "100%"}}>
                    {/* <div className="solicitationsType">
                        <button
                            className={sectionActive === "Em Andamento" ? "active" : ""}
                            onClick={() => {
                                setSectionActive("Em Andamento");
                            }}
                        >
                            <span>Em Andamento</span>
                        </button>
                        <button
                            className={
                                sectionActive === "Pago Aguardando Serviço" ? "active" : ""
                            }
                            onClick={() => {
                                setSectionActive("Pago Aguardando Serviço");
                            }}
                        >
                            <span>Aguardando pagamento</span>
                        </button>
                        <button
                            className={sectionActive === "Recusado" ? "active" : ""}
                            onClick={() => {
                                setSectionActive("Recusado");
                            }}
                        >
                            <span>Recusadas</span>
                        </button>
                    </div> */}
                    <Card>
                        {sectionActive === "Em Andamento"
                            ? renderRooms(chatProgress)
                            : sectionActive === "Pago Aguardando Serviço"
                                ? renderRooms(chatWaiting)
                                : renderRooms(chatRefused)}
                    </Card>
                </DivColumn>
            </DivColumn>
        </Container>
    )
}
export default ListMessages;