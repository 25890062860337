import styled from "styled-components";

export const Container = styled.div`
  height: 90vh;
`

export const Content = styled.div`
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid var(--softgray);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.36);
    /* background-color: ${(props) => (props.isMenu ? "var(--purple)" : "")}; */
    
    width: 100%;

    @media(max-width: 525px) {
      /* height: 200px; */
    }
    
    h2 {
      text-align: center;
      color: var(--purple);
      font-size: 18px;
      margin-left: 1.4rem;
    }

    & > button {
      border: 0;
      cursor: pointer;
      background: transparent;
      font-size: 20px;
      font-weight: 600px;
      color: var(--mediumgray);
      margin-right: 1.5rem;
    }
  }

  main {
    padding: 1.5rem;

    .periodo-container {
      & > select {
        padding: 6px;
        border-radius: 10px;
        margin: 10px 0;
      }
    }

    .select-segment {
      margin: 10px 0 20px 0;

      div {
        display: flex;
        align-items: center;
        margin-top: 10px;
        
        select {
          padding: 6px;
          border-radius: 10px;
          margin-right: 40px;
        }

        label {
          width: 150px;
          display: flex;
          justify-content: center;
          align-items: center;
          
          .exclusividade-content {
            margin: 0;
            display: flex;
            align-items: center;
            
            .exclusividade-input {
              width:  auto;
              overflow-x: auto;
              margin: 0;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  input {
    margin: 10px 0 20px 0;
    width: 100%;
    border: 1px solid var(--mediumgray);
    padding: 8px 10px;
    border-radius: 15px;
  }

  .inputPlace {
    display: flex;
  }

  .inputPlace > select {
    border: 1px solid var(--gray);
    border-radius: 18px;
    padding: 10px 14px 10px 24px;
    margin-right: 10px;
  }
`