import axios from "axios";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { Container, Content } from "./styles";
import api from "../../services"
import { useAuth } from "../../providers/auth";

export function ServiceModal({title, children, shouldOpenModal, setShowNewService, setShouldOpenModal, setSegmentos, confirmVisitor }) {
  const { auth } = useAuth();

  useEffect(() => {

    const fetchData = async () => {
      const response = await api
      .get(`/api/v1/segmentos/`, {
        headers: {
          'Content-Type': 'application/json',
          // 'Authorization': `Bearer ${auth}`,
          },
      })
      .then((response) => {
        setSegmentos(response.data)
      })

      return response;
    }

    if(!confirmVisitor)
      fetchData();
  }, [])

  return (
    <ReactModal
      isOpen={shouldOpenModal}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
      onRequestClose={setShouldOpenModal}
    >
      <Container>
        <Content>
          <header>
            <h2>{title}</h2>
            <button
              onClick={() => {
                setShouldOpenModal(false);
                setShowNewService(false);
              }}
            >
              X
            </button>
          </header>
          <main>
            { children }
          </main>
        </Content>
      </Container>
    </ReactModal>
  );
}