import { useState, useEffect } from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import PaymentPic from '../../assets/PaymentPic.png'
import Button from '../../components/Button/Button'
import Modal from '../../components/Modal/Modal'
import PixLogo from '../../assets/pix-logo.png'
import { useHistory } from "react-router-dom";
import { useAuth } from "../../providers/auth"
import { AboutProposition, Container, Content, RightSide } from "./styles"

const Payment = () => {

  const { auth } = useAuth()

  const history = useHistory()

  const [shouldOpenModal, setShouldOpenModal] = useState(false)
  const [shouldOpenPixModal, setShouldOpenPixModal] = useState(false)

  useEffect(() => {
    if(!auth){
      return history.push("/")
    }
  }, [])

  return(
    <div>
      {shouldOpenModal &&
      (<Modal title={"Adicionar um cartão de crédito"}>
        <div className='contentModal'>
          <div className='cardInputDiv'>
            <h3>Número do cartão</h3>
            <input type="text" />
          </div>
          <div className='cardInputDiv'>
            <h3>Nome no cartão</h3>
            <input type="text" />
          </div>
          <div className='cardInputDiv'>
            <h3>Data de expiração</h3>
            <div className='expirationDate'>
              <select name="" id="">
                <option value=""></option>
              </select>
              <select name="" id="">
                <option value=""></option>
              </select>
            </div>
          </div>
          <div className='cardInputDiv'>
            <h3>Código de segurança (CVV)</h3>
            <input type="text" />
          </div>
          <div className='footerModal'>
            <button className='gray'>Cancelar</button>
            <button className='purple'>Cadastar cartão</button>
          </div>
        </div>
      </Modal>)}
      {shouldOpenPixModal &&
      (<Modal PixModal title={"Pagamento via Pix"}>
        <div className='contentModal'>
          <p className='pixModalText'>Você escolheu a forma de pagamento Pix</p>
          <h4 className='pixModalTextBold'>Nossa chave pix é (celular):</h4>
          <h3 className='pixModalTelNumber'>(XX) XXXXX-XXXX</h3>
          <img src={PixLogo} alt="" />
          <h4 className='pixModalTextBold'>AGUARDANDO CONFIRMAÇÃO DE PAGAMENTO</h4>
          <span className='pixModalTextSpan'>Após a confirmação do pagamento o processo será finalizado e a contratação bem sucedida</span>
        </div>
      </Modal>)}
      <Container>
        {/* <Header isAuth /> */}
        <Content>
          <div className='paymentType'>
            <h2>Método de pagamento</h2>
            <div className='inputBox'>
              <input onClick={() => setShouldOpenPixModal(true)} type="radio" />
              <label htmlFor="pix">Pix</label>
            </div>
            <div className='inputBox'>
              <input type="radio" />
              <label htmlFor="pix">(Crédito) Visa terminando em xxxx</label>
            </div>
            <div className='inputBox'>
              <span>+</span>
              <button onClick={() => setShouldOpenModal(true)}>Cadastrar novo cartão</button>
            </div>
            <AboutProposition>
              <h2>Método de pagamento</h2>
              <p>Influenciador: <span>Ana Mendes</span></p>
              <p>Tempo de duração da divulgação: <span>15 dias</span></p>
              <h4>Valor a ser pago: <span>R$XX</span></h4>
              <div className='btnPlace'>
                <Button 
                  setBackgroundColor="var(--brightpurple)"
                  setColor="var(--white)"
                  // large
                >
                  Finalizar pagamento
                </Button>
              </div>
            </AboutProposition>
          </div>
          <RightSide>
            <img src={PaymentPic} alt="" />
          </RightSide>
        </Content>
        {/* <Footer /> */}
      </Container>
    </div>
  )
}
export default Payment